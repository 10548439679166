import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios"

const Card = () => {

    const [todos, setTodos] = useState([])

    useEffect(() => {
        const getTodos = async () => {
            const res = await axios.get("http://localhost:1212/api/todos")
            setTodos(res.data)
        }

        getTodos()
    }, [])

    const toggleCompleted = async (id) => {
        const res = await axios.put("http://localhost:1212/api/todos/complete/"+id)
        window.location.reload()
    }

    const deleteTodo = async (id) => {
        const res = await axios.delete("http://localhost:1212/api/todos/"+id)
        window.location.reload()
    }

  return (
    <Container>
        <h1>Hello, Chris! <br /> here are your todos:</h1>

        <ul className='todo-list'>
            {todos.map((todo) => (
                <div className='full-todo' key={todo._id}>
                    <li className={`todo ${todo.completed && "crossed"}`}>{todo.title}</li>
                    <span onClick={() => toggleCompleted(todo._id)}><CheckIcon /></span>
                    <span><EditIcon /></span>
                    <span onClick={() => deleteTodo(todo._id)}><DeleteIcon /></span>
            </div>
            ))}
        </ul>
    </Container>
  )
}

export default Card

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    position: absolute;

    .todo-list{
        list-style: none;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 1.5rem;

        .full-todo{
            padding: 10px 25px;
            background-color: #749DEF;
            color: #fff;
            border-radius: 10px;
            font-size: 19px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg{
                cursor: pointer;
            }
        }

        .todo{
            margin-right: 15px;
        }

        .crossed{
            text-decoration: line-through;
        }
    }
`;