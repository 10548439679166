import Card from "./components/Card";
import styled from "styled-components";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Edit from "@mui/icons-material/Edit";

function App() {
  return (
    <Container>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Card />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
}

export default App;


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: relative;
  height: 100vh;
  width: 100vw;
`;